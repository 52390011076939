/* Tiles.css */
.tiles-list {
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.tiles-list li {
  margin: 20px;
  text-align: center;
  position: relative;
  width: 330px; /* Tile width */
  height: 480px; /* Tile height */
  border-radius: 10px; /* Rounded corners */
  overflow: hidden;
  background-color: white;
  transition: box-shadow 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.tiles-list li:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Shadow on hover */
}

.tiles-list li a {
  text-decoration: none;
  color: black;
  font-weight: bold;
  display: block;
  margin-top: 0;
}

.tiles-list li .img-container {
  width: 100%;
  height: 25rem; /* Image container takes 60% of the tile height */
  overflow: hidden;
  position: relative;
}

.tiles-list li img {
  width: 100%; /* Image covers full width of the tile */
  height: 100%; 
  /* Image fully occupies its container */
  object-fit: cover; /* Ensures the image covers the space while maintaining aspect ratio */
  transition: transform 0.3s ease; /* Smooth zoom effect */
  margin-bottom: 0;
}

.tiles-list li:hover img {
  transform: scale(1.1); /* Zoom image on hover */
}

.tiles-list li:hover a {
  color: #40a8e7; /* Change text color on hover */
}

.tiles-list li p {
  font-size: 16px; /* Paragraph text size */
  color: #666; /* Text color for paragraph */
  /* margin-top: 10px; */
  text-align: center;
  font-weight:'bold';
  padding:20px; /* Some padding for better text alignment */
  /* height: 30%; */
   /* Space for the paragraph content */
  overflow: hidden; /* Ensures text doesn't overflow */
}
