/* Header.css */
.nav-link {
    text-decoration: none;
    color: rgb(0, 0, 0);
    font-size: 22px;
    font-weight: bold;
    position: relative; /* Required for ::after positioning */
    transition: color 0.3s ease;
  }
  
  .nav-link::after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    display: block;
    margin-top: 5px;
    background: linear-gradient(to right, #40a8e7, #000000); /* Underline color */
    transition: width 0.3s ease;
  }
  
  .nav-link:hover::after {
    width: 100%; /* Expand the underline */
    left: 0;
  }
  
  .nav-link:hover {
    color: #000000; /* Change text color on hover */
  }
  
  /* Dropdown styles */
  .dropdown {
    position: relative; /* Needed for dropdown positioning */
  }
  
  .dropdown-content { 
    display: none; /* Hidden by default */
    position: absolute; /* Position the dropdown */
    background-color: white; /* Background color for dropdown */
    min-width: 190px; /* Minimum width */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Shadow for dropdown */
    z-index: 1; /* Ensure dropdown is above other elements */
    border-radius: 5px; /* Rounded corners */
  }
  
  .dropdown:hover .dropdown-content {
    display: block; /* Show dropdown on hover */
  }
  
  .dropdown-item {
    color: black; /* Color for dropdown items */
    padding: 6px 8px; /* Padding for items */
    text-decoration: none; /* No underline */
    display: block; /* Make items block elements */
    font-weight: normal; /* Normal weight for dropdown items */
    transition: background 0.3s ease; /* Smooth background transition */
  }
  
  .dropdown-item:hover {
    background: linear-gradient(to right, #40a8e7, #1de7eb); /* Gradient background on hover */
    color: white; /* Change text color on hover */
  }
  /* Contact Container - Style for the contact section */
.contact-container {
  display: flex;
  margin-bottom: 10px;
  align-items: center;
  justify-content: flex-end; /* Align contact details to the right */
}

/* Contact Text - Center text vertically inside the contact container */
.contact-text {
  display: flex;
  color: #000000;
  align-items: center; /* Center text vertically */
}
 
  .hamburger {
    display: none;
    flex-direction: column;
    cursor: pointer;
    gap: 5px;
  }
  
  .hamburger .line {
    width: 30px;
    height: 3px;
    background: black;
  }
  
  .nav-menu {
    display: flex;
  }
  
  .nav-menu.open {
    display: block;
  }
  
  @media (max-width: 768px) {
    .contact-container {
      display: none !important; /* Hide the entire contact container on mobile */
    }
    .hamburger {
      display: flex;
    }
    .nav-menu {
      position: fixed; /* Ensures the menu stays in place */
      top: 80px;
      right: 0;
      width: 100%;  /* Full height for a mobile menu */
      background-color: white;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      transform: translateX(100%); /* Initially hidden off-screen */
      transition: transform 0.3s ease-in-out;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Optional shadow */
      z-index: 1000; /* Above all other content */
    }
    
    .nav-menu.open {
      transform: translateX(0); /* Bring the menu into view when open */
    }
    
  
    /* Hide contact details on mobile */
    .contact-container {
      display: none !important;
    }
    .nav-menu.open .nav-link {
      font-size: 5vw; /* Adjust to your preference */
    }
  }
  

  /* General styles for navigation links */
.nav-menu .nav-link {
  font-size: 2vw; /* Default font size for the links */
}

/* Increase font size for hamburger menu items */
.nav-menu.open .nav-link {
  font-size: 2.4vw; /* Increase the font size when the menu is open */
}

/* Adjust the dropdown menu font size */
.dropdown-content .dropdown-item {
  font-size: 2vw; /* Default size for dropdown items */
}

/* Make sure dropdown font size increases as well */
.nav-menu.open .dropdown-content .dropdown-item {
  font-size: 2.3vw; /* Increased font size when the menu is open */
}
 
/* Dropdown styles */
.dropdown {
  position: relative; /* Needed for dropdown positioning */
}

.dropdown-content {
  display: none; /* Hidden by default */
  position: absolute; /* Position the dropdown */
  background-color: white; /* Background color for dropdown */
  min-width: 190px; /* Minimum width */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Shadow for dropdown */
  z-index: 1; /* Ensure dropdown is above other elements */
  border-radius: 5px; /* Rounded corners */
  transition: opacity 0.3s ease; /* Smooth transition for visibility */
}

.dropdown:hover .dropdown-content {
  display: block; /* Show dropdown on hover */
}

.dropdown-item {
  color: black; /* Color for dropdown items */
  padding: 6px 8px; /* Padding for items */
  text-decoration: none; /* No underline */
  display: block; /* Make items block elements */
  font-weight: normal; /* Normal weight for dropdown items */
  transition: background 0.3s ease; /* Smooth background transition */
}

.dropdown-item:hover {
  background: linear-gradient(to right, #40a8e7, #1de7eb); /* Gradient background on hover */
  color: white; /* Change text color on hover */
}

/* For mobile: Ensure dropdown opens on click */
@media (max-width: 768px) {
  .dropdown:hover .dropdown-content {
    display: none; /* Disable hover effect for mobile */
  }
  
  .dropdown-content.show {
    display: block; /* Show dropdown when `.show` class is added */
  }
}
